import { MenuItem } from "@mui/material"

function GenerationMenuItems()
{
  return [
    <MenuItem key='GMI-16' value={16}>
      16th (Thin-Blood)
    </MenuItem>,
    <MenuItem key='GMI-15' value={15}>
      15th (Thin-Blood)
    </MenuItem>,
    <MenuItem key='GMI-14' value={14}>
      14th (Thin-Blood)
    </MenuItem>,
    <MenuItem key='GMI-13' value={13}>
      13th
    </MenuItem>,
    <MenuItem key='GMI-12' value={12}>
      12th
    </MenuItem>,
    <MenuItem key='GMI-11' value={11}>
      11th
    </MenuItem>,
    <MenuItem key='GMI-10' value={10}>
      10th
    </MenuItem>,
    <MenuItem key='GMI-09' value={9}>
      9th
    </MenuItem>,
    <MenuItem key='GMI-08' value={8}>
      8th
    </MenuItem>,
    <MenuItem key='GMI-07' value={7}>
      7th
    </MenuItem>,
    <MenuItem key='GMI-06' value={6}>
      6th
    </MenuItem>,
    <MenuItem key='GMI-05' value={5}>
      5th (Methuselah)
    </MenuItem>,
    <MenuItem key='GMI-04' value={4}>
      4th (Methuselah)
    </MenuItem>,
    <MenuItem key='GMI-03' value={3}>
      3rd (Antediluvian)
    </MenuItem>,
    <MenuItem key='GMI-02' value={2}>
      Second Generation
    </MenuItem>,
    <MenuItem key='GMI-01' value={1}>
      Cain
    </MenuItem>,
  ]
}

export default GenerationMenuItems;